import Page from "../ui/Page";
import {Plan} from "../ui/Pricing";
import Newsletter from "../ui/Newsletter";
import {TextLink} from "../ui/Base";

export let HOME_URL = "/"

export default function Home() {
    return <Page title="Automate repetitive tasks straight out of your inbox." path={HOME_URL}>
        <div className="m-4 mt-0 max-w-[1024px] h-full mx-auto block">
            <div className="text-center">
                <div className="align-middle my-16 mx-auto inline-block">
                    <div className="inline p-2 font-extrabold margin-y-auto text-5xl text-nowrap md:text-7xl">
                        <div className="w-64 inline-block text-right">
                            <img alt="replybot logo" className="inline h-14 md:h-24 ml-2 -mt-1 aspect-square" src={process.env.PUBLIC_URL + '/img/logo192.webp'} />
                        </div>
                        <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span>
                    </div>
                    <img src={process.env.PUBLIC_URL + "/img/HomeRobot_256.webp"} alt="reply bot robot" className="w-64 inline aspect-square"/>
                </div>
                <h2 className="text-[#004080] text-2xl md:text-4xl">Automate repetitive tasks straight <u className="text-[#D32F2F]">out of your inbox.</u></h2>
            </div>
            <div className="bg-white py-10 md:py-24 lg:py-32">
                <Newsletter/>
            </div>
            <div className="mt-4 max-w-[800px] mx-auto">
                <div className="m-6">
                    <div className="overflow-hidden bg-blue-50 p-8 md:py-4 rounded-2xl md:rounded-b-none">
                        <img src={process.env.PUBLIC_URL + "/img/Running_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:mr-4 md:float-left aspect-square"/>
                        <div className="md:pt-10">
                            <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span> automates repetitive tasks directly from your email, saving your team hours of manual work.
                            This streamlined process not only speeds up response times but also frees your team to focus on more strategic, high-value work.
                            By reducing the manual workload, <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span> boosts productivity and ensures faster resolutions to email-based requests.
                            <br/>
                            <TextLink to={"/docs/benefits_of_replybot"}>Read more here.</TextLink>
                        </div>
                    </div>
                    <div className="overflow-hidden bg-blue-50 p-8 md:py-4 mt-4 md:mt-0 rounded-2xl md:rounded-none">
                        <img src={process.env.PUBLIC_URL + "/img/EasySteps_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:float-right aspect-square"/>
                        <div className="md:pt-16">
                            Get setup in 3 easy steps: <br/>
                            <span className="font-bold">1)</span> Connect your email the same way as email clients. <br/>
                            <span className="font-bold">2)</span> Define actions and the workflows that handle them. <br/>
                            <span className="font-bold">3)</span> Save time, effort and frustration. <br/>
                            <TextLink to={"/docs/setup_replybot"}> Check out our getting started guide.</TextLink>
                        </div>
                    </div>
                    <div className="overflow-hidden bg-blue-50 p-8 md:py-4 mt-4 md:mt-0 rounded-2xl md:rounded-t-none">
                        <img src={process.env.PUBLIC_URL + "/img/Security_256.webp"} alt="reply bot robot" className="h-56 mx-auto md:mr-4 md:float-left aspect-square"/>
                        <div className="md:pt-16">
                            We know how important security and privacy is. <br/>
                            We do not save email body or subjects to our servers, only the metadata we need.
                            Credentials are secured by using customer specific encryption keys and only decrypted when connecting.
                            <TextLink to={"/docs/security_at_replybot"}> Read more here.</TextLink>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <Plan
                        featured
                        name="Pro User"
                        price="$27/month"
                        description="All of the features for one user."
                        href={process.env.REACT_APP_APPURL + "/login"}
                        features={[
                            '15 day free trial',
                            'Unlimited number of actions',
                            'One user',
                            'One mailbox',
                        ]}
                    />
                    <Plan
                        name="Small Business"
                        price="Coming soon!"
                        description="Email managements for teams."
                        features={[
                            '15 day free trial',
                            'Unlimited number of actions',
                            'Multiple users per workspace',
                            'Multiple mailboxes',
                        ]}
                    />
                </div>
            </div>
            <div className="text-center p-10 text-gray-500">
                contact@replybot.io<span className="mx-4">◇</span>© 2024 Jotting, Inc
            </div>
        </div>
    </Page>
}