import {useState} from "react";
import {TextRawLink} from "./Base";


export function NewsletterInner() {
    const [email, setEmail] = useState("");
    const [response, setResponse] = useState<string | undefined>(undefined);

    function registerEmail() {
        fetch(process.env.REACT_APP_API+"RegisterEmail", {
            method: "POST",
            body: JSON.stringify({email: email})
        })
            .then(() => setResponse("Success"))
            .catch(() => setResponse("Error"));
    }

    return (
        <div className="w-full h-32 max-w-md mx-auto lg:col-span-5 lg:pt-2 relative">
            <div className={"transition-opacity w-full absolute " + (response === "Error" ? "" : "opacity-0")}>
                <div className="text-center text-red-500 font-bold text-3xl p-4 rounded-2xl bg-red-50">
                    Something went wrong.
                </div>
            </div>
            <div className={"transition-opacity w-full absolute " + (response === "Success" ? "" : "opacity-0")}>
                <div className="text-center text-blue-500 font-bold text-3xl p-4 rounded-2xl bg-blue-50">
                    You're Signed Up!
                </div>
            </div>
            <div className={"transition-opacity w-full absolute " + (response === undefined ? "" : "opacity-0")}>
                <div className="lg:flex gap-x-4 ">
                    <label htmlFor="email-address" className="sr-only">
                        Email address
                    </label>
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        required
                        placeholder="Enter your email"
                        autoComplete="email"
                        className="min-w-0 w-full lg:w-auto lg:flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm/6"
                    />
                    <button
                        onClick={() => registerEmail()}
                        type="submit"
                        className="w-full lg:w-auto lg:flex-none rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                        Subscribe
                    </button>
                </div>
                <p className="mt-4 text-sm/6 text-slate-800 text-center lg:text-left">
                    Or ask <TextRawLink to={"mailto:contact@replybot.io"}>contact@replybot.io</TextRawLink> to add you to the newsletter.
                </p>
            </div>
        </div>
    )
}

export default function Newsletter() {


    return (
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
                <h2 className="max-w-xl text-balance mx-auto text-3xl font-semibold tracking-tight text-slate-600 sm:text-4xl lg:col-span-7 text-center lg:text-left">
                    Want product news and updates? Sign up for our newsletter.
                </h2>
                <NewsletterInner/>
            </div>
    )
}
