import Page from "../ui/Page";

let NOT_FOUND_URL = "/not_found";

export default function NotFound () {
    return <Page path={NOT_FOUND_URL} title="404: NotFound">
        <div className="m-4 mt-0 max-w-[1024px] h-full mx-auto block">
            <div className="w-1/2 mx-auto text-center">
                <img src={process.env.PUBLIC_URL + '/img/NotFound.webp'} alt="Page Not Found"/>
                <span className="font-bold">Page not found.</span>
            </div>
        </div>
    </Page>
}