import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound";
import Home, {HOME_URL} from "./pages/Home";
import Docs, {DOC_URL, DocsList} from "./pages/Docs";
import Landing, {LANDING_URL} from "./pages/Landing";
import {Crisp} from "crisp-sdk-web";

function App() {
    useEffect(() => {
        Crisp.configure("ef6bd5a5-0bb0-42da-8d3b-12f23b7fa1c6");
    });

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route path={HOME_URL} Component={Home}/>
            <Route path={LANDING_URL} Component={Landing}/>
            <Route path={DOC_URL} Component={DocsList}/>
            <Route path={DOC_URL +"/:article"} Component={Docs}/>
            <Route path="/*" Component={NotFound}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
