import {HOME_URL} from "./Home";
import Page from "../ui/Page";
import {Video} from "../ui/video/Video";
import '../ui/backgrounds.css'
import {useEffect, useState} from "react";
import {Subheader} from "../ui/Base";

export let LANDING_URL = "/landing";

function Steps({time} : {time?: number}) {
    const steps = [
        {startTime: 0, description: "First setup your action description"},
        {startTime: 30, description: "Connect your email"},
        {startTime: 45, description: "Define your workflow in Zapier"},
        {startTime: 80, description: "Send a test email"},
        {startTime: 105, description: "The workflow is run"},
        {startTime: 112, description: "Check the reply"},
    ];

    const [onStep, setOnStep] = useState(0);
    useEffect(() => {
        if (time === undefined) {
            return;
        }
        let lastStep = 0;
        for (let i= 0; i < steps.length; i++) {
            if (steps[i].startTime < time) {
                lastStep = i
            }
        }
        setOnStep(lastStep);
    }, [time, steps]);

    return <div>
        <h2 className="text-center text-2xl mt-4 mb-4 pb-2 font-bold text-slate-700 border-b-2">Getting Setup is Easy:</h2>
        {steps.map(function(ele, index) {
        let isActive = index === onStep;
        return <div className={"transition-all duration-500 " + (isActive ? "py-2 pl-2 text-2xl bg-blue-100 text-slate-800" : "py-1 pl-4 text-lg text-slate-600")}>
            <span className={"text-center px-1.5 mr-1 font-bold " + (isActive ? "text-blue-500" : "text-blue-300")}>{index + 1}.</span>
            {ele.description}
        </div>
    })}</div>
}

function Signup() {
    return <div className="p-8">
        <h2 className="text-slate-700 text-center text-4xl font-semibold">Sign up now to claim your <span className="text-red-600">first month free!</span></h2>
        <div className="my-4 text-center">
            <button
                onClick={() => undefined}
                type="submit"
                className="w-full lg:w-auto lg:flex-none rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
                Sign Up
            </button>
        </div>
    </div>
}

function Why() {
    return <>
        <Subheader> <span className="text-slate-800 font-semibold">Why Choose</span> <span className="text-[#004080] font-semibold">Reply</span><span className="text-[#D32F2F] font-semibold">Bot</span><span className="text-blue-700 font-semibold">.io</span>?</Subheader>
        <ul className="mx-6 py-2 list-image-logo list-inside">
            <li className="mt-2 text-slate-800"><b>Save Time & Increase Efficiency:</b> Automate routine email tasks, freeing your team to focus on high-value activities.</li>
            <li className="mt-2 text-slate-800"><b>24/7 Availability:</b> Deliver instant, around-the-clock responses for faster resolutions and happier customers.</li>
            <li className="mt-2 text-slate-800"><b>Reduce Errors:</b> Ensure accuracy and consistency with predefined workflows and professional replies.</li>
        </ul>
        <p className="mx-6 mt-4 text-slate-800">
            By identifying tasks, running workflows, and sending prompt replies, <span className="text-[#004080] font-semibold">Reply</span><span className="text-[#D32F2F] font-semibold">Bot</span><span className="text-blue-700 font-semibold">.io</span> saves time, reduces errors, and boosts productivity—all while delivering exceptional customer experiences.
        </p>
    </>;
}

function LandingBento() {
    const [time, setTime] = useState(0);

    let baseClass = "relative overflow-hidden rounded-xl border-2 lg:border-4 m-1 lg:m-0 bg-white ";

    return <div className="grid grid-cols-1 gap-6 lg:grid-cols-5 lg:grid-rows-3">
            <div className={baseClass + "border-gray-200 relative lg:col-span-3 lg:rounded-br-3xl lg:order-4"}>
                <Signup/>
            </div>
            <div className={baseClass + "border-gray-300 lg:col-span-2 lg:row-span-2 lg:rounded-tl-3xl lg:order-1"}>
                <Steps time={time}/>
            </div>
            <div className={baseClass + "border-blue-300 lg:col-span-3 lg:row-span-3 lg:rounded-tr-3xl lg:order-2"}>
                <Video setTime={setTime}/>
            </div>
            <div className={baseClass + "border-blue-200 relative lg:col-span-2 lg:row-span-2 lg:rounded-bl-3xl lg:order-3"}>
                <Why/>
            </div>
        </div>
}

export default function Landing() {
    return <Page title="Automate repetitive tasks straight out of your inbox." path={HOME_URL} backgroundClass="landing-bento-background relative">
        <div className="absolute top-[40%] left-[30%] w-80 h-80 bg-blue-700 rounded-full opacity-40 mix-blend-multiply filter blur-3xl animated-blob"></div>
        <div className="absolute top-[50%] left-[50%] w-80 h-80 bg-gray-300 rounded-full opacity-60 mix-blend-multiply filter blur-2xl animated-blob blob-delay-1"></div>
        <div className="absolute top-[30%] right-[30%] w-80 h-80 bg-[#004080] rounded-full opacity-50 mix-blend-multiply filter blur-3xl animated-blob blob-delay-2"></div>
        <div className="mx-auto max-w-[1490px]">
            <h1 className="text-center text-6xl font-bold text-[#004080] my-10 sm:my-16">Automate repetitive tasks <span className="text-[#D32F2F]">straight out of your inbox.</span></h1>
            <div className="mb-16">
                <LandingBento/>
            </div>
        </div>
    </Page>
}