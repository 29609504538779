import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import docs from "./docs/docs.json";
import NotFound from "./NotFound";
import "../polyfill/MarkdownPolyfill.ts";
import {PanelPage} from "../ui/PanelPage";
import Markdown from "../ui/Markdown";
import {Header, TextLink} from "../ui/Base";
import YAML from 'js-yaml';

export let DOC_URL = "/docs"

function titleify(snake: String) {
    return snake.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
        .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_

}

interface ParsedMarkdown {
    content: string,
    metadata: any
}

function parseMarkDown(markdown: string) : ParsedMarkdown {
    if (markdown.startsWith("---\n")) {
        let endIndex = markdown.indexOf("\n---\n", 4);
        return {
            content: markdown.substring(endIndex + 5),
            metadata: YAML.load(markdown.substring(4, endIndex))
        }
    } else {
        return {
            content: markdown,
            metadata : {}
        }
    }
}

export function DocsList() {
    return <PanelPage path={"/docs"} title={"Documentation"}>
        <Header>Documentation</Header>
        <p className="p-4 pb-0">
            Check out our documentation below.
        </p>
        <ul className="list-image-logo list-inside p-6">
            {docs.docs.map(function(ele) {
                return <li><TextLink to={"/docs/" + ele}>{titleify(ele)}</TextLink></li>
            })}
        </ul>
    </PanelPage>
}

export default function Docs() {
    let { article } = useParams();
    const [articleData, setArticleData] = useState<ParsedMarkdown|undefined|null>();

    useEffect(() => {
        if (article !== undefined) {
            import("./docs/" + article + ".md")
                .then(import_data => fetch(import_data.default))
                .then(response => response.text())
                .then(parseMarkDown)
                .then(setArticleData)
                .catch(_ => setArticleData(null));
        }
    }, [article]);

    if (article === undefined || articleData === null) {
        return <NotFound/>
    }

    return <PanelPage path={"/docs/" + article} title={titleify(article)} description={articleData?.metadata.description}>
        <Markdown source={articleData !== undefined ? articleData.content : ""}/>
        <div className="pb-24"/>
    </PanelPage>
}
