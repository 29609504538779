import {ReactNode} from "react";
import {Link} from "react-router-dom";

export function Header({children} : {children:ReactNode}) {
    return <h1 className="text-2xl bg-blue-50 p-4 px-6">{children}</h1>
}

export function Subheader({children} : {children:ReactNode}) {
    return <h2 className="text-2xl border-b border-blue-100 p-2 px-6">{children}</h2>
}

export function Subsubheader({children} : {children:ReactNode}) {
    return <h3 className="text-xl border-b border-blue-100 p-2 ml-6">{children}</h3>
}

export function TextLink({children, to} : {children:ReactNode, to:string}) {
    return <Link to={to} className="font-semibold text-[#004080] hover:text-blue-700">{children}</Link>
}

export function TextRawLink({children, to, target} : {children:ReactNode, to:string|undefined, target?:string}) {
    return <a href={to} target={target} className="font-semibold text-[#004080] hover:text-blue-700">{children}</a>
}