import {Link} from "react-router-dom";
import {ReactNode} from "react";
import {Helmet} from "react-helmet";
import {TextLink, TextRawLink} from "./Base";

let appTitle = "ReplyBot.io";
let defaultDescription = "Automate repetitive tasks straight out of your inbox.\n" +
    "Simply connect your email inbox, define your actions and the workflows to automate them and replybot handles the rest!";

export type PageProps = {
    children: ReactNode,
    path:string,
    title:string | undefined,
    description?:string,
    backgroundClass?:string
}

export default function Page({children, path, title, description, backgroundClass} : PageProps) {
    let calcTitle = title === undefined ? appTitle : title + " | " + appTitle;
    return <>
            <Helmet>
                <title>{calcTitle}</title>
                <meta name="title" content={calcTitle}/>
                <meta property="og:title" content={title === undefined ? appTitle : title}/>
                <meta name="description" property="og:description" content={description === undefined? defaultDescription : description}/>
                <meta name="canonical" property="og:url" content={process.env.REACT_APP_HOSTNAME + path}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={process.env.REACT_APP_HOSTNAME + "/img/Social.webp"} />
                <link rel="apple-touch-icon" href={process.env.PUBLIC_URL + "/img/logo192.png"} />
            </Helmet>
            <div className="h-full flex flex-col">
                <div className="w-full p-4 bg-[#F0F0F0] align-middle border-b">
                    <div className="max-w-[1280px] mx-auto">
                        <div className="inline">
                            <div className="inline p-2 font-extrabold text-3xl text-nowrap">
                                <Link to={"/"}>
                                    <img alt="replybot logo" className="inline h-12 mr-1 -mt-1 aspect-square" src={process.env.PUBLIC_URL + '/img/logo192.webp'} />
                                    <span className="text-[#004080]">Reply</span><span className="text-[#D32F2F]">Bot</span><span className="text-blue-700">.io</span>
                                </Link>
                            </div>
                        </div>
                        <div className="inline float-right h-full">
                            <div className="p-2">
                                <TextRawLink to={process.env.REACT_APP_APPURL}>Login</TextRawLink>
                            </div>
                        </div>
                        <div className="md:inline mx-auto mt-4 ml-0 md:ml-6">
                            <span className="ml-6 md:ml-0">
                                <TextLink to={"/docs/setup_replybot"}>Getting started</TextLink>
                            </span>
                            <span className="ml-6">
                                <TextLink to={"/docs/security_at_replybot"}>Security</TextLink>
                            </span>
                            <span className="ml-6">
                                <TextLink to={"/docs"}>Docs</TextLink>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={"flex-grow " + (backgroundClass === undefined ? "" : backgroundClass)}>
                    {children}
                </div>
            </div>
        </>
}
