import ReactMarkdown from "react-markdown";
import {Header, Subheader, Subsubheader, TextRawLink} from "./Base";
import React, {ReactNode} from "react";

export default function Markdown({source} : {source:string}) {

    function getFirstCharacter(node: ReactNode) : string {
        if (typeof node === 'string') {
            return node.charAt(0);
        } else if (Array.isArray(node)) {
            let i = 0;
            while (node[i] === '\n') {
                i++;
            }
            return getFirstCharacter(node[i]);
        } else if (React.isValidElement(node)) {
            // If it's a React element, recursively get the first character of its children
            return getFirstCharacter(node.props.children);
        } else {
            return "";
        }
    }

    return <ReactMarkdown components={{
        h1({children, ...rest}) {
            return <Header>{children}</Header>
        },
        h2({children, ...rest}) {
            return <Subheader>{children}</Subheader>
        },
        h3({children, ...rest}) {
            return <Subsubheader>{children}</Subsubheader>
        },
        p({children, ...rest}) {
            return <p className={"mx-6 py-2"}>{children}</p>
        },
        pre({children, className, ...rest}){
            return <pre className="mx-8 p-2 bg-blue-50 border border-blue-800 rounded-xl">{children}</pre>
        },
        code({children, ...rest}){
            return <span>{children}</span>
        },
        ul({children, ...rest}){
            return <ul className="mx-6 py-2 list-image-logo list-inside">{children}</ul>
        },
        li({children, ...rest}){
            return <li>{children}</li>
        },
        a({children, href , ...rest}){
            return <TextRawLink to={href} target="_blank">{children}</TextRawLink>
        },
        blockquote({children, ...rest}){
            let char  = getFirstCharacter(children);
            let colorClasses = "bg-blue-50 border-l-blue-500";
            if (char === "❗") {
                colorClasses = "bg-red-100 border-l-red-500";
            } else if (char === "⚠") {
                colorClasses = "bg-yellow-50 border-l-yellow-500"
            }
            return <blockquote className={"border-l-2 mx-6 " + colorClasses}>{children}</blockquote>
        },
    }}>{source}</ReactMarkdown>
}
